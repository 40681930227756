import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import axios from "axios";
import ToastCommon from "../../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../../configs/api-config";
import moment from "moment";
import { sendNotificationWeb } from "../../../../util/common/sendNotificationWeb";
import { sendNotification } from "../../../../util/common/sendNotification";
import { connect } from "react-redux";

function ResubmitForm(props) {
  const { register, handleSubmit, errors } = useForm();
  const [reason, setReason] = useState("");
  const [resubmitDisabled, setResubmitDisabled] = useState(true);
  const { addToast } = useToasts();
  const [candidateName, setCandidateName] = useState(false);
  const [workflowStatus, setWorkflowStatus] = useState(null);
  const [registrationIds, setRegistrationIds] = useState([]);
  const [maintainsIds, setMaintainsIds] = useState([]);
  const [bookingsIds, setBookingsIds] = useState([]);
  const [headhuntersIds, setHeadhuntersIds] = useState([]);
  const { closeModal1, rowID, reset, auth, referenceForm, candidateId } = props;
  const MexxarApi = REGISTER_CANDIDATE;

  const changeHandler = (event) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    setReason(trimmedValue);
    setResubmitDisabled(trimmedValue === "");
  };

  const getDataProfile = () => {
   
    const apiUrl = `${MexxarApi || ""}/${candidateId}`; 

    axios
      .get(apiUrl) 
      .then((res) => {
        const fetchedWorkflowStatus = res?.data?.body?.[0]?.workFlowStatus;
        const fetchedRegistrars = res?.data?.body?.[0]?.registrars || []; 
            const fetchedMaintains = res?.data?.body?.[0]?.maintains || [];
            const fetchedBookings = res?.data?.body?.[0]?.bookings || [];
            const fetchedHeadhunters = res?.data?.body?.[0]?.headhunters || [];

            setWorkflowStatus(fetchedWorkflowStatus);
            setRegistrationIds(fetchedRegistrars.map(reg => reg.id)); 
            setMaintainsIds(fetchedMaintains.map(maintain => maintain.id));
            setBookingsIds(fetchedBookings.map(booking => booking.id));
            setHeadhuntersIds(fetchedHeadhunters.map(headhunter => headhunter.id));
      })
      .catch((err) => {
        console.error("Error fetching profile:", err); 
       
      })
      .finally(() => {
      
      });

  };

 

  const resubmitForm = () => {
    axios
      .patch(`${HOSTMexxar}reference-form/resubmit/${rowID}`, { body: reason }) 
      .then((res) => {
        setCandidateName(res?.data?.body?.candidate?.candidateName);
        if (res.data.status === "success") {
          // Handle success
          reset();

          const notificationContent = `Hi, ${candidateName}'s reference form is Resubmited`; 
          let userIdsToSend = [];

          if (workflowStatus === "MAINTENANCE") {
              userIdsToSend = [...maintainsIds, ...bookingsIds]; 
          } else if (workflowStatus === "REGISTRATION") {
              userIdsToSend = registrationIds;
          } else if (workflowStatus === "HEADHUNTER") {
              userIdsToSend = headhuntersIds;
          }

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: notificationContent,
            candidateId: auth?.item?.id,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: notificationContent,
            candidateId: candidateId,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotificationWeb({
            action: "Resubmited",
            tittle: "Reference form",
            body: notificationContent,
            userId: candidateId,
            date: moment().toISOString(),
          });
          userIdsToSend.forEach(userId => { 
            sendNotificationWeb({
                action: "Resubmited",
                tittle: "Reference form",
                body: notificationContent,
                userId: userId, 
                date: moment().toISOString(),
            });
        });

        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getDataProfile();
    

  }, [candidateId]);
 
  return (
    <Form>
      <FormGroup row>
        <Label sm={4}>Reason</Label>
        <Col sm={8}>
          <Input
            onChange={changeHandler}
            type="text"
            name="reason"
            invalid={errors.reason}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allows 30 characters",
              },
            })}
          />
          {errors.reason && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.reason.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>
      <Row className="justify-content-end">
        <div className="d-flex">
          <Button
            className="mr-2"
            color="success"
            onClick={() => {
              resubmitForm();
              closeModal1();
            }}
            disabled={resubmitDisabled}
          >
            Resubmit
          </Button>
          <Button color="secondary" onClick={() => closeModal1()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ResubmitForm);
