import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Alert,
  Label,
  FormGroup,
  Form,
  Badge,
  UncontrolledTooltip,
  Row,
  CardBody,
  Card,
  Collapse,
  Col,
} from "reactstrap";

import {
  CANDIDATE_SPECIALITY,
  FRAMEWORK,
  REGISTER_CANDIDATE,
  CANDIDATE_ROLE,
  CANDIDATE_BAND,
  CLIENTS,
  EMPLOYEE_PERMISSIONS,
  HOSTMexxar,
  HostMexxarURL,
  TRUSTS,
  ClientLable,
} from "../../../configs/api-config";

import axios from "axios";
import "./profile.style.css";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import PersonalNote from "./widgets/personalNote";
import { useToasts } from "react-toast-notifications";
import { forEach, isNull } from "lodash";
import ToastCommon from "../../../components/toastCommon";
import AdvanceDetails from "./advanceDetails/advanceDetails";
import IsPrivileged from "../../../util/auth/privilegeHelper.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faToggleOff,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import HospitalsMap from "../../../widgets/hospitalsMap/hospitalsMap";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import AvatarMexxar from "../../../util/common/avatarMexxar";
import notNull from "../../../util/common/notNull";
import usePostalCodeValidation from "../../../customHooks/usePostalCodeValidation";
import ModalComponent from "../../../components/Modal/modalComponent";
import LikeTrustClientAddAdvance from "../../../components/Modal/ModalBody/likeTrustClientAddAdvance";
import useClientsFetch from "../../../customHooks/useClientsFetch.js";
import moment from "moment";
import DateInput from "../../../util/common/DateInput.js";

const Profile = (props) => {
  const { auth, resetCandidate, onTabChange, candidate } = props;
  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();
  const MexxarApiTrusts = TRUSTS;
  const MexxarApiFramework = FRAMEWORK;
  const MexxarApiRole = CANDIDATE_ROLE;
  const MexxarApiBand = CANDIDATE_BAND;
  const MexxarApiCandidate = REGISTER_CANDIDATE;
  const MexxarApiEmployee = EMPLOYEE_PERMISSIONS;

  const [value, setValue] = useState("");
  const [trusts, setTrusts] = useState(null);
  const [editable, setEditable] = useState(true);
  const [speciality, setSpeciality] = useState(null);
  const [postalCode, setpostalCode] = useState(null);
  const [candidateData, setCandidateData] = useState([]);
  const [headunterName, setHeadunterName] = useState(null);
  const [registeresName, setRegisteresName] = useState(null);
  const [maintenacerName, setMaintenacerName] = useState(null);
  const [bookingConsultantName, setBookingConsultantName] = useState(null);
  const [unSavedItem, setUnSavedItem] = useState(false);

  const [clientMapStatus, setClientMapStatus] = useState(false);

  /////validations//////
  const [prefferedClientError, setPrefferedClientError] = useState(false);
  const [selectedSpecialitiesError, setSelectedSpecialitiesError] =
    useState(false);

  ////....setting new values to each select array fields.........//////
  const [clientData, setClientData] = useState(null);
  const [clientDataSecondary, setClientDataSecondary] = useState(null);
  const [trustData, setTrustData] = useState(null);
  const [potentialClientData, setPotentialClientData] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);
  const [currentClientSecondary, setClientsSecondary] = useState(null);

  const [registerData, setRegisterData] = useState(null);
  const [headhunterData, setHeadhunterData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState(null);
  const [preferedFrameworks, setPreferedFrameworks] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState(null);
  const [bookingConsultantData, setBookingConsultantData] = useState(null);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [unverifiedCandidateNoteModal, setUnverifiedCandidateNoteModal] =
    useState(false);

  const [role, setRole] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const [framework, setFramework] = useState(null);
  const [bands, setBands] = useState(null);
  const [selectedBand, setSelectedBand] = useState(null);
  const [availableClients, setAvailableClients] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState("");

  const [payementMethod, setPayementMethod] = useState([
    { id: "PAYEE", name: "Payee" },
    { id: "LIMITED", name: "Limited" },
    { id: "UMBRELLA", name: "Umbrella" },
  ]);

  const { errorPostCode, postalData, setPostalData, setPostalCode } =
    usePostalCodeValidation();

  const { loadingClients, errorClients, rowsClients, hasMoreClients } =
    useClientsFetch(HOSTMexxar + "clients/filter?", query, pageNumber);

  useEffect(() => {
    setAvailableClients([]);
    setPageNumber(1);
  }, [query]);

  useEffect(() => {
    setAvailableClients([]);
    if (rowsClients) {
      const mappedClients = rowsClients.map((client) => ({
        id: client.id,
        value: client.name,
        label: client.name,
      }));

      setAvailableClients((prevClients) => [...prevClients, ...mappedClients]);
    }
  }, [rowsClients]);

  const handleMenuScrollToBottom = () => {
    if (hasMoreClients && !loadingClients) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const handleInputChange = (inputValue) => {
    setQuery(inputValue);
  };

  useDidMountEffect(() => {
    setSelectedBand(candidateData?.candidateBand?.id);
    setSelectedRole(candidateData?.candidateRole?.id);
    return () => {};
  }, [candidateData]);

  ////....check if update or not the select array fields......////
  const chekIfUpdateSelectField = (data, idList) => {
    let tempArray = [];

    if (data !== null) {
      forEach(data, function (item) {
        tempArray.push(item.id);
      });
    } else {
      tempArray = idList;
    }

    return tempArray;
  };

  const formatDateToYYYYMMDD = (date) => {
    return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
  };
  const [dateOfBirth, setDateOfBirth] = useState("");

  useEffect(() => {
    if (candidateData?.dateOfBirth) {
      setDateOfBirth(formatDateToDDMMYYYY(candidateData.dateOfBirth));
    }
  }, [candidateData]);

  const formatDateToDDMMYYYY = (dateString) => {
    // Assuming dateString is in YYYY-MM-DD format
    const [year, month, day] = dateString.split("-");
    // Return in MM/DD/YYYY format
    return `${day}/${month}/${year}`;
  };


  const onSubmit = async (data) => {
    let specilityIds = selectedSpecialities.map((item) => item.id);
    
   
    let prefferedClientIds = candidateData?.preferredClients?.map((item) => item.id) || [];
    
    
    let newPreferredClients = clientData
      .filter(client => !prefferedClientIds.includes(client.id))
      .map(client => client.id);
  
    
    let allPreferredClients = [...new Set([...prefferedClientIds, ...newPreferredClients])];
  
    let secondaryPreferredClientsIds = candidateData?.secondaryPreferredClients?.map((item) => item.id) || [];
    let prefferedTrustIds = candidateData?.preferredTrusts?.map((item) => item.id) || [];
    let potentialClientIds = potentialClientData.map((item) => item.id);
    let headhunterIds = headhunterData.map((item) => item.id);
    let registerationIds = candidateData?.registrars?.map((item) => item.id) || [];
    let maintenanceIds = candidateData?.maintains?.map((item) => item.id) || [];
    let bookingIds = candidateData?.bookings?.map((item) => item.id) || [];
    let likeWardsIds = candidateData?.likeWards?.map((item) => item.id) || [];
    let disLikeWardsIds = candidateData?.dislikeWards?.map((item) => item.id) || [];
  
    const formattedDOB = dateOfBirth !== "" ? formatDateToYYYYMMDD(dateOfBirth) : null;
  
    if (clientData && !selectedSpecialitiesError && clientDataSecondary) {
      try {
       
        const candidateUpdateResponse = await axios.put(MexxarApiCandidate, {
          id: candidateData?.id,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          email: data.email,
          avatar: candidateData?.avatar,
          primaryMobile: data.primaryMobile,
          secondaryMobile: data.secondaryMobile,
          landLine: data.landLine,
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.state,
          postCode: data.postCode.toUpperCase(),
          candidateServiceType: candidateData?.candidateServiceType,
          linkedIn: candidateData?.linkedIn,
          facebookUrl: candidateData?.facebookUrl,
          gender: candidateData?.status == "UNVERIFIED" ? data.gender : candidateData?.gender,
          title: data.title,
          candidateRoleId: Number(data.candidateRoleId),
          candidateBandId: Number(data.candidateBandId),
          dateOfBirth: formattedDOB,
          headhunters: chekIfUpdateSelectField(headhunterData, headhunterIds),
          registrars: chekIfUpdateSelectField(registerData, registerationIds),
          maintains: chekIfUpdateSelectField(maintenanceData, maintenanceIds),
          bookings: chekIfUpdateSelectField(bookingConsultantData, bookingIds),
          candidateSpecialitiesIds: chekIfUpdateSelectField(selectedSpecialities, specilityIds),
          preferredClientsIds: allPreferredClients, // **Send both new & previous clients**
          secondaryPreferredClientsIds: chekIfUpdateSelectField(currentClientSecondary, secondaryPreferredClientsIds),
          potentialClientsIds: chekIfUpdateSelectField(potentialClientData, potentialClientIds),
          preferredTrustsIds: trustData !== null ? chekIfUpdateSelectField(trustData, prefferedTrustIds) : [],
          currentlyEmployedAtId: currentClient ? currentClient.id : null,
          locality: data.locality,
          workFlowStatus: candidateData?.workFlowStatus,
          status: candidateData?.status,
          likeWards: likeWardsIds,
          dislikeWards: disLikeWardsIds,
          paymentMethodStatus: data?.payementMethod ? data.payementMethod : candidateData?.paymentMethodStatus,
          acceptedClientId: candidateData?.acceptedClient,
          auditedOn: candidateData?.auditedOn,
          callResponseStatus: candidateData?.callResponseStatus,
          candidateApplicationStatus: candidateData?.candidateApplicationStatus,
          candidateSourceId: candidateData?.candidateSource?.id,
          clearance: candidateData?.clearance,
          referenceFormSendDate0: candidateData?.referenceFormSendDate0,
          referenceFormSendDate1: candidateData?.referenceFormSendDate1,
          referenceFormSendStatus0: candidateData?.referenceFormSendStatus0,
          referenceFormSendStatus1: candidateData?.referenceFormSendStatus1,
          referenceFormSenderType: candidateData?.referenceFormSenderType,
        });

        if (allPreferredClients.length > 0) {
          await preferredClients(candidateData?.id, allPreferredClients);
        }
  
        if (candidateUpdateResponse.data.status === "success") {
          ToastCommon(true, "success", addToast);
          getData();
          resetCandidate();
          setUnSavedItem(false);
        } else if (candidateUpdateResponse.data.status === "duplicate") {
          ToastCommon(true, "duplicateEmail", addToast);
        } else if (candidateUpdateResponse.data.message === "Postal code not found") {
          ToastCommon(true, "postCode", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      } catch (err) {
        ToastCommon(true, "error", addToast);
      }
    }
  
    // **Validation Errors**
    if (isNull(selectedSpecialities)) {
      setSelectedSpecialitiesError(true);
    }
    if (isNull(clientData) && candidateData?.candidateServiceType === "TEMPORARY") {
      setPrefferedClientError(true);
    }
  };
  
  

  const fetchAllMasterData = async () => {
    const cacheKey = "allOtherData"; // Unique key for the cache
    const cachedData = sessionStorage.getItem(cacheKey);
    if (cachedData) {
      return JSON.parse(cachedData);
    }

    try {
      const responses = await Promise.all([
        axios.get(MexxarApiBand),
        // axios.get(MexxarApiClients),
        axios.get(
          HOSTMexxar + "clients/filter?&page=1&offset=20&status=ACTIVE"
        ),
        axios.get(MexxarApiFramework),
        axios.get(MexxarApiRole),
        axios.get(MexxarApiEmployee + "/permissions/BOOKING"),
        axios.get(MexxarApiEmployee + "/permissions/MAINTENANCE"),
        axios.get(MexxarApiEmployee + "/permissions/REGISTRATION"),
        axios.get(MexxarApiEmployee + "/permissions/HEADHUNTER"),
        axios.get(MexxarApiTrusts),
      ]);

      // Store the responses in the cache
      sessionStorage.setItem(cacheKey, JSON.stringify(responses));
      return responses;
    } catch (err) {
      // ToastCommon(true, "error", addToast);
      throw err; // Re-throw error to handle it in the calling function
    }
  };



  const createOptionsObject = (items) =>
    items.map(({ name, id }) => ({ value: name, label: name, id }));

  const createOptions = (items) =>
    items.map(({ firstName, lastName, id }) => ({
      value: firstName + " " + lastName,
      label: firstName + " " + lastName,
      id,
    }));

  const processMasterDataResponses = (responses) => {
    const [
      bandsRes,
      clientsRes,
      frameworksRes,
      rolesRes,
      bookingRes,
      maintenanceRes,
      registrationRes,
      headhunterRes,
      trustsRes,
    ] = responses;

    //use createOptionsObject since the data structures are different

    const clientArray = createOptionsObject(clientsRes.data.body?.content);
    const trustsArray = createOptionsObject(trustsRes.data.body);
    const frameworkArray = createOptionsObject(frameworksRes.data.body);

    //use createOptions since the data structures are different
    const bookingConsultants = createOptions(bookingRes.data.body);
    const maintenanceConsultants = createOptions(maintenanceRes.data.body);
    const registrationConsultants = createOptions(registrationRes.data.body);
    const headhuntingConsultants = createOptions(headhunterRes.data.body);

    setBookingConsultantName(bookingConsultants);
    setMaintenacerName(maintenanceConsultants);
    setRegisteresName(registrationConsultants);
    setHeadunterName(headhuntingConsultants);

    setFramework(frameworkArray);
    setTrusts(trustsArray);
    setBands(bandsRes.data.body);
    setRole(rolesRes.data.body);
  };

  const getOtherData = async () => {
    try {
      const responses = await fetchAllMasterData();
      processMasterDataResponses(responses);
    } catch (err) {
      // Handle error if needed
    }
  };

  const getSpecialityData = async () => {
    if (!selectedRole) return;

    try {
      const response = await axios.get(
        `${HOSTMexxar}candidate-specialities/role/${selectedRole}`
      );
      const specialitiesArray = response.data.body.map((item) => ({
        value: item.name,
        label: `${item.name}${item.type === "DATABASE" ? "-DB" : ""}`,
        id: item.id,
      }));

      setSpeciality(specialitiesArray);
    } catch (err) {
      console.error(err);
    }
  };

  useDidMountEffect(() => {
    getSpecialityData();

    return () => {};
  }, [selectedRole]);

  const organizeData = (data) => {
    const preferredClients = data.preferedClients || [];

    setCandidateData(data);
    setpostalCode(data.postalCode);

    setPreferedFrameworks(
      preferredClients.map((client) => client.framework.name)
    );

    setClientData(createOptionsObject(data.preferredClients || []));
    setClientDataSecondary(
      createOptionsObject(data.secondaryPreferredClients || [])
    );
    setTrustData(createOptionsObject(data.preferredTrusts || []));
    setPotentialClientData(createOptionsObject(data.potentialClients || []));
    setSelectedSpecialities(
      createOptionsObject(data.candidateSpecialities || [])
    );

    setCurrentClient(
      data.currentlyEmployedAt
        ? {
            value: data.currentlyEmployedAt.name,
            label: data.currentlyEmployedAt.name,
            id: data.currentlyEmployedAt.id,
          }
        : null
    );

    setHeadhunterData(createOptions(data.headhunters || []));
    setRegisterData(createOptions(data.registrars || []));
    setMaintenanceData(createOptions(data.maintains || []));
    setBookingConsultantData(createOptions(data.bookings || []));
  };

  const changeHandler = ({ target }) => {
    setValue(target?.value?.toUpperCase());
  };

  const fetchData = async (candidateId) => {
    try {
      const response = await axios.get(`${MexxarApiCandidate}/${candidateId}`);
      return response.data.body[0];
    } catch (err) {
      ToastCommon(true, "error", addToast);
      throw err; // Re-throw error to handle it in the calling function
    }
  };

  const likeWards = async (candidateId, likeWardsIds) => {
    try {
      const response = await axios.put(`${HOSTMexxar}candidates/liked-wards`, {
        candidateId,
        clientWards: likeWardsIds,
      });
      return response.data.body[0];
    } catch (err) {
      ToastCommon(true, "error", addToast);
      throw err;
    }
  };


  const preferredClients = async (candidateId,prefferedClientIds) => {
    try {
      const response = await axios.put(
        `${HOSTMexxar}candidates/preferred-clients`,
        {
          candidateId,
          preferredClients: prefferedClientIds,
        }
      );
      return response.data.body[0];
    } catch (err) {
      ToastCommon(true, "error", addToast);
      throw err;
    }
  };

  const getData = async () => {
    try {
      const pathArray = window.location.pathname.split("/");
      const CandidateId = Number(pathArray[3]);
      const data = await fetchData(CandidateId);

      organizeData(data);

      await getOtherData(data);
      await getSpecialityData(data);
    } catch (err) {
      // Handle error if needed
    }
  };

  useDidMountEffect(() => {
    let currentClientInPotentialClient = [];
    if (currentClient) {
      currentClientInPotentialClient =
        potentialClientData &&
        potentialClientData.filter(function (client) {
          return client.id === currentClient.id;
        });

      if (
        currentClientInPotentialClient &&
        currentClientInPotentialClient.length > 0
      ) {
        setPotentialClientError(false);
      } else {
        setPotentialClientError(true);
      }
    }
    return () => {};
  }, [currentClient]);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };

  useEffect(() => {
    let data = candidate;
    organizeData(data);
    getOtherData(data);
    getSpecialityData(data);

    return () => {};
  }, []);

  useEffect(() => {
    //removing prefferedClients field validation error
    if (clientData && clientData.length) {
      setPrefferedClientError(false);
    }
    if (clientDataSecondary && clientDataSecondary.length) {
      setPrefferedClientError(false);
    }
    if (selectedSpecialities && selectedSpecialities.length) {
      setSelectedSpecialitiesError(false);
    }
    return () => {};
  }, [clientData, clientDataSecondary]);

  const [potentialClientError, setPotentialClientError] = useState(false);
  const [basicInfo, setBasicInfo] = useState(false);
  const [emailAddressInfo, setEmailAddressInfo] = useState(false);
  const [addressInfo, setAddressInfo] = useState(false);
  const [mobileInfo, setMobileInfo] = useState(false);
  const [showAdvanceAddModal, setShowAdvanceAddModal] = useState(false);

  useDidMountEffect(() => {
    let currentClientInPotentialClient = [];
    if (currentClient) {
      currentClientInPotentialClient =
        potentialClientData &&
        potentialClientData.filter(function (client) {
          return client.id === currentClient.id;
        });

      if (
        currentClientInPotentialClient &&
        currentClientInPotentialClient.length > 0
      ) {
        setPotentialClientError(false);
      } else {
        setPotentialClientError(true);
      }
    }
    return () => {};
  }, [currentClient]);

  const [canVerify, setCanVerify] = useState({
    canVerify: false,
    error: false,
  });

  const activateCandidate = () => {
    axios
      .put(
        MexxarApiCandidate +
          "/" +
          candidateData?.id +
          "/" +
          "status" +
          "/" +
          "ACTIVE"
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "update", addToast);
          getData();
          resetCandidate();
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const verificationValidityCheck = () => {
    getData();
    resetCandidate();

    if (
      selectedSpecialities &&
      selectedBand &&
      selectedRole &&
      candidateData?.title != "None" &&
      candidateData?.houseNumber &&
      candidateData?.streetName &&
      candidateData?.primaryMobile &&
      basicInfo &&
      emailAddressInfo &&
      addressInfo &&
      mobileInfo &&
      candidateData?.gender !== "Unknown"
    ) {
      setCanVerify({ canVerify: true, error: false });
    } else {
      setCanVerify({ canVerify: false, error: true });
    }
  };

  const addPrefferedTrustsAndClients = (data) => {
    axios
      .patch(
        HOSTMexxar + "candidates/" + candidateData.id + "/preferredClients",
        data.clients
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });

    axios
      .patch(
        HOSTMexxar + "candidates/" + candidateData.id + "/preferredTrusts",
        data.trust
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });

    window.location.reload();
  };

  const addHeadhunter = (consultants) => {
    const consultantIds = consultants.map((consultant) => consultant.id);
    axios
      .patch(HOSTMexxar + "candidates/" + candidateData.id + "/addHeadhunter", {
        consultantIds: consultantIds,
      })
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const handleAddConsultant = () => {
    addHeadhunter(headhunterData);
  };

  const [consultantIds, setConsultantIds] = useState([]);

  return (
    <>
      <div
        className="card"
        style={{
          border:
            candidateData?.status === "UNVERIFIED"
              ? "1px solid orange"
              : "none",
        }}
      >
        {candidateData?.status === "UNVERIFIED" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: "20px" }}>
              This candidate needs to be assigned to a consultant
            </div>
            <Button
              className="mr-2"
              color="success"
              onClick={() => setIsOpenFilter(!isOpenFilter)}
            >
              Consultant Assign
            </Button>
          </div>
        )}
      </div>

      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Assign/Assigned Consultants</Label>

                <Select
                  value={headhunterData}
                  isMulti
                  styles={colourStyles}
                  name="headhunters"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => {
                    if (Array.isArray(data)) {
                      const selectedConsultantIds = data.map(
                        (option) => option.value
                      );

                      setConsultantIds(selectedConsultantIds);
                    }
                    setHeadhunterData(data);
                    setUnSavedItem(true);
                  }}
                  options={headunterName}
                />
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <br />
                  <Button
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={handleAddConsultant}
                  >
                    Add
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>

      {candidateData?.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          {/* Loading animation */}
          <div>
            <img src={loadingClients} style={{ height: 100 }}></img>
          </div>
        </div>
      ) : (
        <div className="row">
          <div
            className={` ${
              auth.item.userType == "EMPLOYEE" ? "col-md-7" : "col-md-12"
            }  col-sm-12`}
          >
            <div
              className="card "
              style={{
                border:
                  candidateData?.status == "UNVERIFIED" && "1px solid orange",
              }}
            >
              <br />
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header d-flex   justify-content-between">
                  <div>
                    {" "}
                    <h5 className="card-title heading-personal ">
                      Basic Information
                      {candidateData?.status == "UNVERIFIED" &&
                      candidateData?.importedNotes !== null ? (
                        <>
                          <FontAwesomeIcon
                            id="oldNotes"
                            className="mx-2"
                            icon={faInfoCircle}
                            onClick={() =>
                              setUnverifiedCandidateNoteModal(true)
                            }
                          />
                          <UncontrolledTooltip target="oldNotes">
                            Old notes
                          </UncontrolledTooltip>
                        </>
                      ) : null}
                    </h5>
                  </div>

                  <div></div>
                </div>

                <div className="card-body">
                  <div className="row row-sm">
                    <div className=" candidateID-area col-md-12">
                      <AvatarMexxar
                        candidateId={candidateData?.id}
                        primaryPhone={candidateData?.primaryPhone}
                        avatar={candidateData?.avatar}
                        gender={candidateData?.gender}
                        pointer={false}
                      />

                      <span className="badge badge-warning text-uppercase "></span>
                    </div>

                    <div className="col-md-12 col-sm-12 m-2 ">
                      <Alert
                        color={`${editable ? "danger" : "info"}`}
                        className="d-flex flex-column"
                      >
                        <small className=" ">
                          <span className="text-danger">*</span> Required Fields
                        </small>

                        {candidateData?.status == "UNVERIFIED" && (
                          <>
                            <small>
                              Please fill these data to activate&nbsp;
                              {candidateData?.firstName}&nbsp;
                              {candidateData?.lastName}
                              <ul>
                                <li>Role/Speciality/Band</li>
                                <li>Title, Name and Gender </li>
                                <li>
                                  Address : Property Number/Street
                                  Name/Locality/Town/County
                                </li>
                                <li>Address : Post Code </li>
                                <li>
                                  Mobile Number (Primary)/Mobile Number
                                  (Secondary)/Land Phone Number
                                </li>
                                <li>Payment Method</li>
                              </ul>
                            </small>
                          </>
                        )}
                      </Alert>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="md-form-group ">
                        <Label for="candidateRoleId">
                          Role <span className="text-danger">*</span>
                        </Label>

                        <Input
                          disabled={auth.item.userType === "CANDIDATE"}
                          type="select"
                          id="candidateRoleId"
                          name="candidateRoleId"
                          value={selectedRole}
                          invalid={errors.candidateRoleId || !selectedRole}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                          onChange={(e) => {
                            setSelectedRole(e.target.value);
                            setUnSavedItem(true);
                          }}
                        >
                          <option value={0}>Select</option>
                          {role &&
                            role.map((item) => (
                              <option
                                value={item.id}
                                key={item.id}
                                style={{ color: "#6A6767" }}
                              >
                                {item.name}
                              </option>
                            ))}
                        </Input>

                        {errors.candidateRoleId && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.candidateRoleId?.message}
                            </li>
                          </ul>
                        )}
                        {!selectedRole && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              Please assign a role
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="md-form-group">
                        <Label for="candidateBandId">
                          Band <span className="text-danger">*</span>
                        </Label>
                        <Input
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          type="select"
                          id="candidateBandId"
                          name="candidateBandId"
                          // defaultValue={candidateData?.candidateBand.id}
                          value={selectedBand}
                          invalid={errors.candidateBandId || !selectedBand}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                          onChange={(e) => {
                            setSelectedBand(e.target.value);
                            setUnSavedItem(true);
                          }}
                        >
                          <option value={0}>Select</option>
                          {bands &&
                            bands.map((items, id) => {
                              return (
                                <option
                                  value={items.id}
                                  key={id}
                                  style={{ color: "#6A6767" }}
                                >
                                  {items.name}
                                </option>
                              );
                            })}
                        </Input>
                        {errors.candidateBandId && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.candidateRoleId?.message}
                            </li>
                          </ul>
                        )}
                        {!notNull(selectedBand) || selectedBand == 0 ? (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              Please assign a band
                            </li>
                          </ul>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <div className="md-form-group">
                        <Label for="candidateSpecialityId">
                          Speciality <span className="text-danger">*</span>
                        </Label>

                        {auth.item.userType == "CANDIDATE" ? (
                          selectedSpecialities != null ? (
                            <>
                              {selectedSpecialities.map((speciality, id) => (
                                <Badge key={id} className="m-1 bg-success">
                                  {speciality.value}
                                </Badge>
                              ))}
                            </>
                          ) : null
                        ) : (
                          <>
                            {speciality != null ? (
                              <Select
                                defaultValue={
                                  selectedSpecialities != null
                                    ? selectedSpecialities
                                    : ""
                                }
                                isMulti
                                name="candidateSpecialityId"
                                styles={colourStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(data) => {
                                  setSelectedSpecialities(data);
                                  setUnSavedItem(true);
                                }}
                                options={speciality}
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                              />
                            ) : null}
                            {selectedSpecialitiesError && (
                              <ul
                                className="list-unstyled text-sm mt-1 text-muted filled"
                                id="parsley-id-119"
                              >
                                <li className="parsley-required text-danger">
                                  "Please add at least one speciality"
                                </li>
                              </ul>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2 ">
                      <div className="md-form-group">
                        <label>
                          Title <span className="text-danger">*</span>
                        </label>
                        <Input
                          type="select"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          name="title"
                          id="title"
                          invalid={
                            errors.title || candidateData?.title == "None"
                          }
                          defaultValue={candidateData?.title}
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        >
                          <option value="None" style={{ color: "#6A6767" }}>
                            None
                          </option>
                          <option value="Mr" style={{ color: "#6A6767" }}>
                            Mr.
                          </option>
                          <option value="Mrs" style={{ color: "#6A6767" }}>
                            Mrs.
                          </option>
                          <option value="Ms" style={{ color: "#6A6767" }}>
                            Ms.
                          </option>
                          <option value="Miss" style={{ color: "#6A6767" }}>
                            Miss
                          </option>
                          <option value="Dr." style={{ color: "#6A6767" }}>
                            Dr.
                          </option>
                        </Input>
                        {candidateData?.title == "None" ||
                        !notNull(candidateData?.title) ? (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              Please assign a title
                            </li>
                          </ul>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3 ">
                      <div className="md-form-group float-label">
                        <label>
                          First Name <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="firstName"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          defaultValue={candidateData?.firstName}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <div className="md-form-group float-label">
                        <label>Middle Name</label>
                        <Input
                          name="middleName"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          defaultValue={candidateData?.middleName}
                          innerRef={register}
                          onChange={() => setUnSavedItem(true)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="md-form-group float-label">
                        <label>
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="lastName"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          defaultValue={candidateData?.lastName}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 30,
                              message:
                                "This field only allowed only 30 characters",
                            },
                            pattern: {
                              value: "[A-Z][a-z]* [A-Z][a-z]*",
                              message:
                                "Letters and Spaces are only allowed for this field",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="md-form-group">
                        <label>Date of Birth</label>
                        <DateInput
                          id="dateOfBirth"
                          invalid={false}
                          register={() => {}}
                          value={dateOfBirth}
                          onChange={setDateOfBirth}
                          isDOB={true}
                        />
                      </div>
                    </div>

                    {candidateData?.status == "UNVERIFIED" && (
                      <>
                        <div className="col-md-6 col-sm-12 mb-2">
                          <Label for="gender">
                            Gender <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="select"
                            id="gender"
                            name="gender"
                            defaultValue={candidateData?.gender}
                            invalid={
                              errors.gender ||
                              candidateData?.gender == "Unknown" ||
                              !notNull(candidateData?.gender)
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                            onChange={() => setUnSavedItem(true)}
                          >
                            <option value="" style={{ color: "#6A6767" }}>
                              Select
                            </option>
                            <option value={"Male"} style={{ color: "#6A6767" }}>
                              Male
                            </option>
                            <option
                              value={"Female"}
                              style={{ color: "#6A6767" }}
                            >
                              Female
                            </option>
                            <option
                              value={"Unknown"}
                              style={{ color: "#6A6767" }}
                            >
                              Other
                            </option>
                            {/* <option value={"OTHER"}>Other</option> */}
                          </Input>
                          {errors.gender && (
                            <ul
                              className="list-unstyled text-sm mt-1 text-muted filled"
                              id="parsley-id-119"
                            >
                              <li className="parsley-required text-danger">
                                {errors.gender?.message}
                              </li>
                            </ul>
                          )}

                          {candidateData?.gender == "Unknown" ||
                          !notNull(candidateData?.gender) ? (
                            <ul
                              className="list-unstyled text-sm mt-1 text-muted filled"
                              id="parsley-id-119"
                            >
                              <li className="parsley-required text-danger">
                                Please assign a gender
                              </li>
                            </ul>
                          ) : null}
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                          <Label for="payementMethod">
                            Payment Method
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="select"
                            id="payementMethod"
                            name="payementMethod"
                            defaultValue={candidateData?.paymentMethodStatus}
                            invalid={
                              errors.payementMethod ||
                              !notNull(candidateData?.paymentMethodStatus)
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                            onChange={() => setUnSavedItem(true)}
                          >
                            <option value="" style={{ color: "#6A6767" }}>
                              Select
                            </option>
                            {payementMethod.map((value, i) => {
                              return (
                                <option
                                  value={value.id}
                                  key={i}
                                  style={{ color: "#6A6767" }}
                                >
                                  {value.name}
                                </option>
                              );
                            })}
                          </Input>
                          {errors.payementMethod && (
                            <ul
                              className="list-unstyled text-sm mt-1 text-muted filled"
                              id="parsley-id-119"
                            >
                              <li className="parsley-required text-danger">
                                {errors.payementMethod?.message}
                              </li>
                            </ul>
                          )}
                          {!notNull(candidateData?.paymentMethodStatus) && (
                            <ul
                              className="list-unstyled text-sm mt-1 text-muted filled"
                              id="parsley-id-119"
                            >
                              <li className="parsley-required text-danger">
                                Please assign a payement method
                              </li>
                            </ul>
                          )}
                        </div>
                      </>
                    )}
                    <div className="col-md-12 col-sm-12">
                      {candidateData?.status == "UNVERIFIED" && (
                        <div>
                          <Alert
                            color="warning"
                            className="d-flex align-items-center justify-content-between"
                          >
                            Caution: Please Verify Title/First/Middle/Last Names
                            Gender and Payment Method
                            <div className="round mb-2 mr-2">
                              <input
                                type="checkbox"
                                id="checkboxBasicInfo"
                                checked={basicInfo}
                                onChange={() => setBasicInfo(!basicInfo)}
                              />
                              <label for="checkboxBasicInfo"></label>
                            </div>
                          </Alert>
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="md-form-group">
                        <label>
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="emailShowOnly"
                          disabled={true}
                          hidden={candidateData?.[0]?.status === "INACTIVE"}
                          defaultValue={candidateData?.email}
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 50,
                              message:
                                "This field only allowed only 50 characters",
                            },
                            pattern: {
                              value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email address!",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        />

                        <Input
                          name="email"
                          hidden={true}
                          defaultValue={candidateData?.email}
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 50,
                              message:
                                "This field only allowed only 50 characters",
                            },
                            pattern: {
                              value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email address!",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="md-form-group">
                        <label>Created Date</label>
                        {candidateData?.createdDate ? (
                          <DateInput
                            id="createdDate"
                            invalid={false}
                            register={() => {}}
                            value={formatDateToDDMMYYYY(
                              candidateData?.createdDate
                            )}
                            readOnly={true}
                          />
                        ) : (
                          <Input
                            name="createdDate"
                            type="text"
                            readOnly
                            disabled={true}
                            placeholder="Date is Unavailable"
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      {candidateData?.status == "UNVERIFIED" && (
                        <div>
                          <Alert
                            color="warning"
                            className="d-flex align-items-center justify-content-between"
                          >
                            Caution: Please Verify Email Address
                            <div className="round mb-2 mr-2">
                              <input
                                type="checkbox"
                                id="checkboxEmailAddressInfo"
                                checked={emailAddressInfo}
                                onChange={() =>
                                  setEmailAddressInfo(!emailAddressInfo)
                                }
                              />
                              <label for="checkboxEmailAddressInfo"></label>
                            </div>
                          </Alert>
                        </div>
                      )}
                    </div>
                    <div className="form-row"></div>

                    <div className="form-row">
                      <FormGroup className="col-md-12 mt-3">
                        <label>Address Information</label>
                        <hr className="mt-1 mr-2  " />
                      </FormGroup>

                      <FormGroup className="col-md-2">
                        <Label for="houseNumber">No.</Label>
                        <Input
                          type="text"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          //id="houseNumber"
                          name="houseNumber"
                          // invalid={errors.houseNumber}
                          defaultValue={candidateData?.houseNumber}
                          innerRef={register({
                            maxLength: {
                              value: 30,
                              message:
                                "This field should be less than 30 characters",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        ></Input>
                        {errors.houseNumber && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.houseNumber?.message}
                            </li>
                          </ul>
                        )}
                      </FormGroup>
                      <FormGroup
                        className={`${
                          auth.item.userType == "EMPLOYEE"
                            ? "col-md-5"
                            : "col-md-4"
                        }`}
                      >
                        <Label for="streetName">Street Name</Label>
                        <Input
                          type="text"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          id="streetName"
                          name="streetName"
                          // invalid={errors.streetName}
                          defaultValue={candidateData?.streetName}
                          innerRef={register({
                            maxLength: {
                              value: 200,
                              message:
                                "This field should be less than 30 characters",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        ></Input>
                        {errors.streetName && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.streetName?.message}
                            </li>
                          </ul>
                        )}
                      </FormGroup>
                      <FormGroup
                        className={`${
                          auth.item.userType == "EMPLOYEE"
                            ? "col-md-5"
                            : "col-md-4"
                        }`}
                      >
                        <Label for="locality">Locality</Label>
                        <Input
                          type="text"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          id="locality"
                          name="locality"
                          // invalid={errors.locality}
                          defaultValue={candidateData?.locality}
                          innerRef={register({
                            maxLength: {
                              value: 200,
                              message:
                                "This field should be less than 30 characters",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        ></Input>
                        {errors.locality && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.locality?.message}
                            </li>
                          </ul>
                        )}
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-4">
                        <Label for="city">Town</Label>
                        <Input
                          type="text"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          id="city"
                          name="city"
                          //value={value}
                          onChange={(e) => {
                            changeHandler(e);
                            setUnSavedItem(true);
                          }}
                          //  invalid={errors.city}
                          defaultValue={candidateData?.city}
                          innerRef={register({
                            maxLength: {
                              value: 200,
                              message:
                                "This field should be less than 30 characters",
                            },
                          })}
                        ></Input>
                        {errors.city && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.city?.message}
                            </li>
                          </ul>
                        )}
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <Label for="state">County</Label>
                        <Input
                          type="text"
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          id="state"
                          name="state"
                          invalid={errors.state}
                          value={
                            postalData?.county
                              ? postalData?.county
                              : candidateData?.state
                          }
                          onChange={(e) => {
                            setPostalData({
                              ...postalData,
                              county: e.target.value,
                            });
                            setUnSavedItem(true);
                          }}
                          innerRef={register({
                            maxLength: {
                              value: 200,
                              message:
                                "This field should be less than 30 characters",
                            },
                          })}
                        ></Input>
                        {errors.state && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.state?.message}
                            </li>
                          </ul>
                        )}
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <Label for="postCode">Post Code</Label>

                        {
                          //    isUndefined(candidateData?.postalCode) || isNull(candidateData?.postalCode) ? null : (
                          <Input
                            type="text"
                            id="postCode"
                            name="postCode"
                            className="capitalizeUpperCase"
                            disabled={
                              auth.item.userType == "CANDIDATE" ? true : false
                            }
                            invalid={errors.postCode || errorPostCode}
                            defaultValue={
                              isNull(candidateData?.postalCode)
                                ? ""
                                : candidateData?.postalCode?.code
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                              maxLength: {
                                value: 10,
                                message:
                                  "This field should be less than 10 characters",
                              },
                            })}
                            onChange={(e) => {
                              setUnSavedItem(true);
                              setPostalCode(e.target.value);
                            }}
                          ></Input>
                        }
                        {errors.postCode && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.postCode?.message}
                            </li>
                          </ul>
                        )}
                        {errorPostCode && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              Postal code is not valid!
                            </li>
                          </ul>
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      {candidateData?.status == "UNVERIFIED" && (
                        <div>
                          <Alert
                            color="warning"
                            className="d-flex align-items-center justify-content-between"
                          >
                            Caution: Please Verify Address (House-Apartment
                            No./Street Name/Locality/Town/County/Post Code )
                            <div className="round mb-2 mr-2">
                              <input
                                type="checkbox"
                                id="checkboxAddressInfo"
                                checked={addressInfo}
                                onChange={() => setAddressInfo(!addressInfo)}
                              />
                              <label for="checkboxAddressInfo"></label>
                            </div>
                          </Alert>
                        </div>
                      )}
                    </div>

                    <div className="col-sm-4">
                      <div className="md-form-group">
                        <label>
                          Mobile Number (Primary)
                          <span className="text-danger">*</span>
                        </label>
                        <Input
                          defaultValue={candidateData?.primaryMobile}
                          name="primaryMobile"
                          id="primaryMobile"
                          hidden={candidateData?.[0]?.status === "INACTIVE"}
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          //value={"+44 1632 960345"}
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          invalid={errors.primaryMobile}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            pattern: {
                              value:
                                /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                              message: "Please enter a valid phone number",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        />
                        {errors.primaryMobile && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.primaryMobile?.message}
                            </li>
                            <li className="parsley-required text-danger">
                              +44 xxxx xxxx xxx
                            </li>
                            <li className="parsley-required text-danger">
                              0xxxx xxxx xxx
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="md-form-group">
                        <label>Mobile Number (Secondary)</label>
                        <Input
                          defaultValue={candidateData?.secondaryMobile}
                          name="secondaryMobile"
                          id="secondaryMobile"
                          hidden={candidateData?.[0]?.status === "INACTIVE"}
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          invalid={errors.secondaryMobile}
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          innerRef={register({
                            pattern: {
                              value:
                                /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                              message: "Please enter a valid phone number",
                            },
                          })}
                          onChange={() => setUnSavedItem(true)}
                        />
                        {errors.secondaryMobile && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.secondaryMobile?.message}
                            </li>
                            <li className="parsley-required text-danger">
                              +44 xxxx xxxx xxx
                            </li>
                            <li className="parsley-required text-danger">
                              0xxxx xxxx xxx
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="md-form-group">
                        <label>Land Phone Number (Optional)</label>
                        <Input
                          defaultValue={candidateData?.landLine}
                          name="landLine"
                          hidden={candidateData?.[0]?.status === "INACTIVE"}
                          invalid={errors.landLine}
                          disabled={
                            auth.item.userType == "CANDIDATE" ? true : false
                          }
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          innerRef={register({
                            pattern: {
                              value:
                                /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                              message: "Please enter valid phone number",
                            },
                          })}
                        />
                        {errors.landLine && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.landLine?.message}
                            </li>
                            <li className="parsley-required text-danger">
                              +44 xxxx xxxx xxx
                            </li>
                            <li className="parsley-required text-danger">
                              0xxxx xxxx xxx
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      {candidateData?.status == "UNVERIFIED" && (
                        <div>
                          <Alert
                            color="warning"
                            className="d-flex align-items-center justify-content-between"
                          >
                            Caution: Please Verify Mobile Number
                            (Primary/Secondary) and Land Phone Number
                            <div className="round mb-2 mr-2">
                              <input
                                type="checkbox"
                                id="checkboxMobileInfo"
                                checked={mobileInfo}
                                onChange={() => setMobileInfo(!mobileInfo)}
                              />
                              <label for="checkboxMobileInfo"></label>
                            </div>
                          </Alert>
                        </div>
                      )}
                    </div>

                    <IsPrivileged privilege={["EMPLOYEE", "ADMIN"]}>
                      {/*only show for temporary candidates */}
                      {candidateData?.candidateServiceType === "TEMPORARY" && (
                        <>
                          <div className="col-sm-8 ">
                            <div className="md-form-group">
                              <label>
                                Preferred/Assigned Trusts
                                <span className="text-danger">*</span>
                              </label>

                              {trusts != null ? (
                                <Select
                                  defaultValue={
                                    trustData != null ? trustData : ""
                                  }
                                  isMulti
                                  name="trusts"
                                  id="trusts"
                                  invalid={errors.trusts}
                                  styles={colourStyles}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(data) => {
                                    setTrustData(data);
                                    setUnSavedItem(true);
                                  }}
                                  options={trusts}
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                              ) : null}
                              {errors.trusts && (
                                <ul
                                  className="list-unstyled text-sm mt-1 text-muted filled"
                                  id="parsley-id-119"
                                >
                                  <li className="parsley-required text-danger">
                                    "Please add at least one trust"
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-4 d-flex align-items-end">
                            <div className="md-form-group">
                              <Button
                                color="success"
                                onClick={() => setShowAdvanceAddModal(true)}
                              >
                                Advance Add
                              </Button>
                            </div>
                          </div>
                        </>
                      )}

                      {/*only show for temporary candidates */}
                      {candidateData?.candidateServiceType === "TEMPORARY" && (
                        <div className="col-sm-12 ">
                          <div className="md-form-group">
                            <label>
                              Preferred/Assigned {ClientLable}s
                              <span className="text-danger">*</span>
                            </label>

                            {availableClients ? (
                              <Select
                                defaultValue={
                                  clientData != null ? clientData : ""
                                }
                                isMulti
                                name="clients"
                                id="clients"
                                invalid={!!errorClients}
                                styles={colourStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(data) => {
                                  setClientData(data);
                                  setUnSavedItem(true);
                                }}
                                options={availableClients}
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                                isLoading={loadingClients}
                                onInputChange={handleInputChange}
                                onMenuScrollToBottom={handleMenuScrollToBottom}
                                noOptionsMessage={() =>
                                  loadingClients
                                    ? "Loading..."
                                    : "No more clients"
                                }
                              />
                            ) : null}
                            {prefferedClientError && (
                              <ul
                                className="list-unstyled text-sm mt-1 text-muted filled"
                                id="parsley-id-119"
                              >
                                <li className="parsley-required text-danger">
                                  "Please add at least one preferred client"
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      )}

                      {/*only show for permanent candidates */}
                      {candidateData?.candidateServiceType === "PERMANENT" && (
                        <div className="col-sm-12 ">
                          <div className="md-form-group">
                            <label>
                              Potential {ClientLable}s
                              {/* <span className="text-danger mx-1">*</span> */}
                            </label>

                            {clientData != null && (
                              <Select
                                defaultValue={
                                  potentialClientData != null
                                    ? potentialClientData
                                    : ""
                                }
                                isMulti
                                name="potentialClients"
                                id="potentialClients"
                                invalid={errors.potentialClients}
                                styles={colourStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                //  / onChange={(data) =>  onChanagePotentialClients(data)}
                                onChange={(data) => {
                                  setUnSavedItem(true);
                                  setPotentialClientData(data);
                                }}
                                options={clientData}
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                                onInputChange={handleInputChange}
                                onMenuScrollToBottom={handleMenuScrollToBottom}
                                noOptionsMessage={() =>
                                  loadingClients
                                    ? "Loading..."
                                    : "No more clients"
                                }
                              />
                            )}
                            {/* havent added validation for this yet.if needed add by changing below */}
                            {potentialClientError && (
                              <ul
                                className="list-unstyled text-sm mt-1 text-muted filled"
                                id="parsley-id-119"
                              >
                                <li className="parsley-required text-danger">
                                  Note: Please add{" "}
                                  {currentClient && currentClient.label} back as
                                  a potential {ClientLable} for this candidate
                                  to show the {ClientLable} status
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      )}

                      {/*only show for permanent candidates */}
                      {candidateData?.candidateServiceType === "PERMANENT" && (
                        <div className="col-sm-12 ">
                          <div className="md-form-group">
                            <label>
                              Currently Employeed At
                              {/* <span className="text-danger">*</span> */}
                            </label>

                            {
                              <Select
                                value={currentClient}
                                name="currentClient"
                                id="currentClient"
                                invalid={errors.currentClient}
                                styles={colourStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(data) => {
                                  setCurrentClient(data);
                                  setUnSavedItem(true);
                                }}
                                options={potentialClientData}
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                              />
                            }
                          </div>
                        </div>
                      )}

                      {candidateData?.candidateServiceType === "TEMPORARY" && (
                        <div className="col-sm-12 ">
                          <div className="md-form-group">
                            <label>
                              Secondary Preferred/Assigned {ClientLable}s
                              <span className="text-danger">*</span>
                            </label>

                            {availableClients != null ? (
                              <Select
                                defaultValue={
                                  clientDataSecondary != null
                                    ? clientDataSecondary
                                    : ""
                                }
                                readOnly
                                isMulti
                                name="currentClientSecondary"
                                id="currentClientSecondary"
                                invalid={errors.currentClientSecondary}
                                styles={colourStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(data) => {
                                  setClientsSecondary(data);
                                  setUnSavedItem(true);
                                }}
                                options={availableClients}
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                                onInputChange={handleInputChange}
                                onMenuScrollToBottom={handleMenuScrollToBottom}
                                noOptionsMessage={() =>
                                  loadingClients
                                    ? "Loading..."
                                    : "No more clients"
                                }
                              />
                            ) : null}
                            {prefferedClientError && (
                              <ul
                                className="list-unstyled text-sm mt-1 text-muted filled"
                                id="parsley-id-119"
                              >
                                <li className="parsley-required text-danger">
                                  "Please add at least one preferred client"
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="col-sm-12 ">
                        <div className="md-form-group">
                          <label>
                            Assign/Assigned Headhunters{" "}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          {headunterName != null ? (
                            <Select
                              defaultValue={
                                headhunterData != null ? headhunterData : ""
                              }
                              isMulti
                              styles={colourStyles}
                              name="headhunters"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(data) => {
                                setHeadhunterData(data);
                                setUnSavedItem(true);
                              }}
                              options={headunterName}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          ) : null}
                        </div>
                      </div>

                      {/*only allowed for temp staffing */}
                      {candidateData?.candidateServiceType === "TEMPORARY" ? (
                        <>
                          {" "}
                          <div className="col-sm-12 ">
                            <div className="md-form-group">
                              <label>
                                Assign/Assigned Registration Consultants{" "}
                                <span className="text-danger">*</span>
                              </label>
                              {registeresName != null ? (
                                <Select
                                  defaultValue={
                                    registerData != null ? registerData : ""
                                  }
                                  isMulti
                                  name="registrations"
                                  styles={colourStyles}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(data) => {
                                    setRegisterData(data);
                                    setUnSavedItem(true);
                                  }}
                                  options={registeresName}
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-12 ">
                            <div className="md-form-group">
                              <label>
                                Assign/Assigned Maintenance Consultants{" "}
                                <span className="text-danger">*</span>
                              </label>
                              {maintenacerName != null ? (
                                <Select
                                  defaultValue={
                                    maintenanceData != null
                                      ? maintenanceData
                                      : ""
                                  }
                                  isMulti
                                  name="maintenance"
                                  styles={colourStyles}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(data) => {
                                    setMaintenanceData(data);
                                    setUnSavedItem(true);
                                  }}
                                  options={maintenacerName}
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-12 ">
                            <div className="md-form-group">
                              <label>
                                Assign/Assigned Booking Consultants{" "}
                                <span className="text-danger">*</span>
                              </label>
                              {bookingConsultantName != null ? (
                                <Select
                                  defaultValue={
                                    bookingConsultantData != null
                                      ? bookingConsultantData
                                      : ""
                                  }
                                  isMulti
                                  name="bookingConsultants"
                                  styles={colourStyles}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(data) => {
                                    setBookingConsultantData(data);
                                    setUnSavedItem(true);
                                  }}
                                  options={bookingConsultantName}
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </IsPrivileged>
                  </div>
                </div>

                <IsPrivileged privilege={["EMPLOYEE", "ADMIN"]}>
                  {candidateData?.status == "UNVERIFIED" && unSavedItem && (
                    <Alert
                      color="danger"
                      className="d-flex align-items-center justify-content-between mx-2 text-sm"
                    >
                      Caution: Please save any changes before activating the
                      candidate
                    </Alert>
                  )}

                  {candidateData?.status == "UNVERIFIED" && canVerify.error && (
                    <Alert
                      color="danger"
                      className="d-flex align-items-center justify-content-between mx-2 text-sm pb-0"
                    >
                      <ul>
                        <li>
                          Please make sure that all the data is Correct and
                          Verified
                        </li>
                        <li>
                          Save the changes before activating the candidate.
                        </li>
                      </ul>
                    </Alert>
                  )}

                  <div className="d-flex justify-content-end mb-3 mr-3 ">
                    <Button className="mr-2" color="success" type="submit">
                      Save
                    </Button>
                    {candidateData?.status == "UNVERIFIED" ? (
                      canVerify.canVerify && !canVerify.error ? (
                        <Button
                          className="mr-2"
                          color="success"
                          type="button"
                          disabled={unSavedItem}
                          onClick={activateCandidate}
                        >
                          Activate
                        </Button>
                      ) : (
                        <Button
                          className="mr-2"
                          color="warning"
                          type="button"
                          disabled={unSavedItem}
                          onClick={verificationValidityCheck}
                        >
                          Check Validity
                        </Button>
                      )
                    ) : null}
                  </div>
                </IsPrivileged>
              </Form>
            </div>
          </div>

          <IsPrivileged
            privilege={["EMPLOYEE", "ADMIN", "EMPLOYEE_TEMP", "EMPLOYEE_PERM"]}
          >
            <div className="  col-md-5 col-sm-12 ">
              <div className="row ">
                <div className="  col-md-12 col-sm-12 ">
                  <div className="card">
                    <div className="row ">
                      <div className="  col-md-12 ">
                        <h5 className="ml-3 mt-3">Personal Note</h5>
                        <PersonalNote candidateId={candidateData?.id} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-12 col-sm-12 ">
                  <div className="card" style={{ height: "1330px" }}>
                    <div className="card-header d-flex justify-content-between  ">
                      <strong>{ClientLable} Map</strong>

                      {clientMapStatus ? (
                        <FontAwesomeIcon
                          id="clientMapToggle"
                          icon={faToggleOn}
                          onClick={() => setClientMapStatus(false)}
                          className="mr-1 btn-raised  fa-solid pointer shadow-none  text-success"
                          style={{ fontSize: "20px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          id="clientMapToggle"
                          icon={faToggleOff}
                          onClick={() => setClientMapStatus(true)}
                          style={{ fontSize: "20px" }}
                          className=" mr-1  btn-raised  fa-solid pointer shadow-none  text-danger"
                        />
                      )}
                      <UncontrolledTooltip target="clientMapToggle">
                        Enable / Disable {ClientLable} Map feature
                      </UncontrolledTooltip>
                    </div>
                    <div className="p-2">
                      {!clientMapStatus && (
                        <Alert color="danger">
                          Caution: Map disabled. Enable to use feature.
                        </Alert>
                      )}
                    </div>

                    <div className="card-body">
                      <div className="row "></div>
                      <div className="row">
                        <div className="col-12">
                          <HospitalsMap
                            mode="inidividual"
                            candidateId={candidateData?.id}
                            //  postalCode={candidateData?.postalCode}
                            enable={clientMapStatus}
                            postalCode={
                              postalCode != null
                                ? postalCode
                                : candidateData?.postalCode
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </IsPrivileged>

          {/*only allowed for temp staffing */}
          {/*until we add all the advance details ,do not use this*/}
          <IsPrivileged privilege={["EMPLOYEE", "ADMIN"]}>
            {candidateData?.candidateServiceType === "TEMPORARY" ? (
              <div className="col-md-12 col-sm-12">
                <AdvanceDetails candidateId={candidateData?.id} />
              </div>
            ) : null}
          </IsPrivileged>

          <ModalComponent
            show={unverifiedCandidateNoteModal}
            header="Old Notes"
            closeModal={() => setUnverifiedCandidateNoteModal(false)}
          >
            <div className="d-flex p-1 m-3 text-color">
              <p> {candidateData?.importedNotes} </p>
            </div>
          </ModalComponent>

          <ModalComponent
            show={unverifiedCandidateNoteModal}
            header={`Add ${ClientLable}s/Trust`}
            closeModal={() => setShowAdvanceAddModal(false)}
          >
            <LikeTrustClientAddAdvance
              candidateData={candidateData}
              onCreate={(data) => {
                addPrefferedTrustsAndClients(data);
              }}
              closeModal={() => setShowAdvanceAddModal(false)}
            />
          </ModalComponent>

          <ModalComponent
            show={showAdvanceAddModal}
            header={`Add ${ClientLable}s/Trust`}
            closeModal={() => setShowAdvanceAddModal(false)}
          >
            <LikeTrustClientAddAdvance
              candidateData={candidateData}
              onCreate={(data) => {
                addPrefferedTrustsAndClients(data);
              }}
              closeModal={() => setShowAdvanceAddModal(false)}
            />
          </ModalComponent>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(Profile);
