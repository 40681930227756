import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import ToastCommon from "../../../toastCommon";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import newClient from "../newClient";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../../configs/api-config";
import { sendNotification } from "../../../../util/common/sendNotification";
import { sendNotificationWeb } from "../../../../util/common/sendNotificationWeb";
import moment from "moment";
import { connect } from "react-redux";

function RejectForm(props) {
  const { register, handleSubmit, errors } = useForm();
  // const MexxarApiRoot = HOSTMexxar;
  const [addKillLog, setaddKillLog] = useState(false);
  const [rejectDisabled, setRejectDisabled] = useState(true);
  const [workflowStatus, setWorkflowStatus] = useState(null);
  const [registrationIds, setRegistrationIds] = useState([]);
  const [maintainsIds, setMaintainsIds] = useState([]);
  const [bookingsIds, setBookingsIds] = useState([]);
  const [headhuntersIds, setHeadhuntersIds] = useState([]);
  const MexxarApi = REGISTER_CANDIDATE;

  const { addToast } = useToasts();
  const {
    onCreate,
    closeModa12,
    mode,
    modalType,
    fieldDataOne,
    fieldDataTwo,
    rowNoteID,
    rowID,
    auth,
    rowvalue,
    action,
    getData,
    reset,
    referenceForm,
  } = props;
  const [value1, setValue1] = useState("");

  // const changeHandler1 = ({ target }) => {
  //     setValue1(target.value);
  // };

  const changeHandler1 = (event) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    setValue1(trimmedValue);
    setRejectDisabled(trimmedValue === "");
  };


  const getDataProfile = () => {

    const apiUrl = `${MexxarApi || ""}/${referenceForm?.candidate?.id}`;

    axios
      .get(apiUrl)
      .then((res) => {
        const fetchedWorkflowStatus = res?.data?.body?.[0]?.workFlowStatus;
        const fetchedRegistrars = res?.data?.body?.[0]?.registrars || [];
        const fetchedMaintains = res?.data?.body?.[0]?.maintains || [];
        const fetchedBookings = res?.data?.body?.[0]?.bookings || [];
        const fetchedHeadhunters = res?.data?.body?.[0]?.headhunters || [];

        setWorkflowStatus(fetchedWorkflowStatus);
        setRegistrationIds(fetchedRegistrars.map(reg => reg.id));
        setMaintainsIds(fetchedMaintains.map(maintain => maintain.id));
        setBookingsIds(fetchedBookings.map(booking => booking.id));
        setHeadhuntersIds(fetchedHeadhunters.map(headhunter => headhunter.id));
      })
      .catch((err) => {
        console.error("Error fetching profile:", err);

      })
      .finally(() => {

      });

  };


  const RejectForm = () => {
    const body = "Reference form reject";

    axios
      .patch(
        `${HOSTMexxar}reference-form/rejectForm/${rowID}?rejectReason=${value1}`
      )
      .then((res) => {

        if (res.data.status == "success") {


          let userIdsToSend = [];

          if (workflowStatus === "MAINTENANCE") {
            userIdsToSend = [...maintainsIds, ...bookingsIds];
          } else if (workflowStatus === "REGISTRATION") {
            userIdsToSend = registrationIds;
          } else if (workflowStatus === "HEADHUNTER") {
            userIdsToSend = headhuntersIds;
          }

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi,${referenceForm?.candidateName}'s reference form is rejected`,
            candidateId: auth?.item?.id,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi, ${referenceForm?.candidateName}'s reference form is rejected`,
            candidateId: referenceForm?.candidate?.id,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotificationWeb({
            action: "Rejected",
            tittle: "Reference form",
            body: `Hi, ${referenceForm?.candidateName}'s reference form is rejected`,
            userId: referenceForm?.candidate?.id,
            date: moment().toISOString(),
          });
          userIdsToSend.forEach(userId => {
            sendNotificationWeb({
              action: "Rejected",
              tittle: "Reference form",
              body: `Hi, ${referenceForm?.candidateName}'s reference form is rejected`,
              userId: userId,
              date: moment().toISOString(),
            });
          });


          reset();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  useEffect(() => {
    getDataProfile();


  }, [referenceForm?.candidate?.id]);

  return (
    <Form>
      <FormGroup row>
        <Label sm={4}>Reason</Label>
        <Col sm={8}>
          <Input
            //  value={rowvalue}
            onChange={changeHandler1}
            type="text"
            name="reason"
            // defaultValue={rowvalue}
            invalid={errors.reason}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          ></Input>
          {errors.reason && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.reason?.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>
      <Row className="justify-content-end">
        <div className="d-flex ">
          <Button
            className="mr-2"
            color="success"
            onClick={() => {
              RejectForm();
            }}
            disabled={rejectDisabled}
          >
            Reject
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModa12()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(RejectForm);
