import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { forEach, filter, isEmpty } from "lodash";
import useDidMountEffect from "./intialRunUseEffect";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import { Button, Label, Input, Form, FormGroup, Col, Alert } from "reactstrap";
import {
  REGISTER_CANDIDATE,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  BOOKING_WORKSPACE_CANDIDATELIST,
  HOSTMexxar,
  ClientLable,
  CompanyName,
} from "../../../configs/api-config";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../configs/authConfig";
import { sendEmail } from "../../../pages/email/graph";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import useCandidateList from "../../../customHooks/useCandidateList";
import { sendNotification } from "../../../util/common/sendNotification";
import { useTokenHandler } from "../../../pages/email/outlookFunctions";
import ModalComponent from "../modalComponent";
import ClientRateBookingModal from "./hospitalRateBookingWorkspace";

function BookACandidate(props) {
  const { register, handleSubmit, watch, errors } = useForm();

  const {
    onCreate,
    closeModal,
    candidateId,
    reset,
    currentResetState,
    dayData,
    candidateWhenClick,
    auth,
    incommingPage,
    user,
    userProfile,
  } = props;

  const selectedAccount = JSON.parse(
    localStorage.getItem("selectedOutlookAccount")
  );
  const { checkTokenExpiration } = useTokenHandler();

  const MexxarApi = HOSTMexxar;
  const MexxarShift = SHIFTS;
  const MexxarCandidate = REGISTER_CANDIDATE;
  const MexxarCreateBooking = CREATE_BOOKING;
  const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

  const { addToast } = useToasts();

  const [query, setQuery] = useState("");

  const [check, setCheck] = useState(false);
  const [shifts, setShifts] = useState(null);
  const [likeWards, setLikeWards] = useState([]);
  const [candidates, setCandidates] = useState(null);
  const [likeClients, setLikeClients] = useState([]);
  const [currentShift, setCurrentShift] = useState(0);
  const [selectedWard, setSelectedWard] = useState(0);
  const [dataErrorStatus, setDataErrorStatus] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [previousBooking, setPreviousBooking] = useState(false);
  const [showHospitalRateModal, setShowHospitalRateModal] = useState(false);
  const [candidateSpecialities, setCandidateSpecialities] = useState();
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
    useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState("");
  const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
  const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);
  const [wardCheck, setWardCheck] = useState({
    wardId: null,
    doesNotExistAsLikeWard: false,
    wardDoesNotExist: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [sendConfirmation, setSendConfirmation] = useState(false);
  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = REGISTER_CANDIDATE + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );
  const [showSpecialRates, setShowSpecialRates] = useState(false);
  const [breakMinutes, setBreakMinutes] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [currentGrade, setCurrentGrade] = useState(-1);
  const [ratesConfirmed, setRatesConfirmed] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 5) {
        const time = `${String(hour).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        times.push(time);
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();
  const onSubmit = (data) => {
    if (!dataErrorStatus) {
      addBooking(data);
    }
  };

  console.log("selectedClient", selectedClient);
  console.log("userProfile", userProfile);
  console.log("selectedCandidates", selectedCandidates);
  let shiftStartingDate = moment(userProfile.date).format("YYYY-MM-DD");
  let shiftStartingTime = moment(userProfile.startTime, "HH:mm").format(
    "HH:mm:ss"
  );

  let shiftEndingTime = moment(userProfile.endTime, "HH:mm").format("HH:mm:ss");

  let shiftSTime = moment(shiftStartingDate).add(shiftStartingTime).toDate();
  let shiftETime = moment(shiftStartingDate).add(shiftEndingTime).toDate();

  const addBooking = (data) => {
    let BStatus;
    check ? (BStatus = 1) : (BStatus = 0);

    let currentDate = moment(new Date()).toDate();

    let arrangData = {
      bookingDate: shiftStartingDate,
      bookingStatus: BStatus,
      bookingAttendedStatus: 4,
      createdDate: currentDate,
      referenceNo: userProfile?.refNo,
      candidateId: selectedCandidates.id,
      clientsId: Number(userProfile?.clients?.id),
      specialityId: Number(data.specialityId),
      shiftId: Number(data.shiftId),
      wardId: Number(wardCheck?.wardId),
      shiftTo: shiftETime,
      shiftFrom: shiftSTime,
      bonus: data.bonus !== undefined ? Number(data?.bonus) : 0,
      upLift: data.upLift !== undefined ? Number(data?.upLift) : 0,
      totalHours:
        data.totalHours !== undefined ? parseInt(data?.totalHours, 10) : 0,
      breakTime:
        data.breakTime !== undefined ? parseInt(data?.breakTime, 10) : 0,
    };
    setDisableCreateButton(true);

    axios
      .post(MexxarCreateBooking, arrangData)
      .then((res) => {
        if (res.data.status == "success") {
          closeModal();
          updateBookedShift();
          //sending the confirmation email to the candidate

          if (!previousBooking) {
            sendBookingConfirmationEmail(
              arrangData,
              selectedCandidates,
              Number(userProfile?.clients?.id),
              Number(userProfile.departmentId)
            );
            sendNotification({
              notificationType: "BOOKING",
              title: "New Booking Created",
              content:
                "Ref: " +
                `${userProfile?.refNo}` +
                " | " +
                `${userProfile?.clients.name}` +
                " | " +
                userProfile?.ward +
                " | " +
                shiftStartingDate +
                " | " +
                moment(shiftStartingTime, "HH:mm:ss").format("HH:mm") +
                " to " +
                moment(shiftEndingTime, "HH:mm:ss").format("HH:mm"),
              startDate: shiftStartingDate,
              addToast: addToast,
            });
            deleteRow(userProfile?.id); //delete the row after making the booking
          }

          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
          setDisableCreateButton(false);
        }
      })
      .catch((err) => {
        // setDisableCreateButton(false);
        console.log("eroor------->", err);
        ToastCommon(true, "error", addToast);
      });
  };

  const { instance, accounts } = useMsal();

  //Function : sending the confirmation email to the candidate
  const sendBookingConfirmationEmail = (
    data,
    candidateWhenClick,
    selectedClient,
    selectedWard
  ) => {
    let subject = `Booking Confirmation: ${data?.referenceNo}`;
    let body = `<p>Hello ${candidateWhenClick?.value},</p>
                <p>We are writing to confirm your booking for ${moment(
                  data?.bookingDate
                ).format(
                  "ddd, DD-MM-YYYY"
                )} with ${CompanyName}. Please find the details below:</p>
                <ul>
                    <li>Reference no: ${data?.referenceNo}</li>
                    <li>Date: ${moment(data?.bookingDate).format(
                      "ddd, DD-MM-YYYY"
                    )}</li>
                    <li>${ClientLable}: ${selectedClient?.name}</li>
                    <li>Ward: ${selectedWard?.name}</li>
                    <li>Start time: ${moment
                      .utc(data?.shiftFrom)
                      .local()
                      .format("h:mm A")}</li>
                    <li>End time: ${moment
                      .utc(data?.shiftTo)
                      .local()
                      .format("h:mm A")}</li>
                </ul>
    `;

    let to = [
      {
        id: selectedCandidates?.id,
        value: selectedCandidates?.candidate?.email,
        label: selectedCandidates?.candidate?.email,
      },
    ];

    let cc = [];
    let bcc = [];
    let file = [];

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount ? selectedAccount : accounts[0],
      })
      .then((response) => {
        sendEmail(response?.accessToken, to, cc, bcc, subject, body, file, user)
          .then((response) => {
            ToastCommon(true, "sent", addToast);
            closeModal();
          })
          .catch((error) => {});
      })
      .catch((error) => console.log(error));
  };

  const updateBookedShift = () => {
    const formData = new FormData();
    formData.append("booked", true); // Adding the booked field as form data

    axios
      .put(
        HOSTMexxar + "otherShift/updatedBookedShift/" + userProfile?.id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensures it's sent as form data
          },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          reset();
        }
      })
      .catch((err) => {
        console.log("Error updating booked shift:", err);
      });
  };

  const getDateField = () => {
    axios
      .get(
        MexxarApiBookingWorkspace +
          "?startDate=" +
          moment(dateSelected).format("YYYY/MM/DD") +
          "&endDate=" +
          moment(dateSelected).add(1, "days").format("YYYY/MM/DD")
      )
      .then((res) => {
        // setInitialRows(res.data.body);

        let listOfCandidates = [];
        for (let i = 0; i < res.data.body.length; i++) {
          listOfCandidates.push({
            value:
              res.data.body[i].candidate.firstName +
              " " +
              res.data.body[i].candidate.lastName,
            label:
              res.data.body[i].candidate.firstName +
              " " +
              res.data.body[i].candidate.lastName,
            id: res.data.body[i].candidate.id,
            // candidate: res.data.body[i],
          });
        }
        setCandidates(listOfCandidates);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const deleteRow = (id) => {
    axios
      .delete(HOSTMexxar + "otherShift/" + id)
      .then((res) => {
        // ToastCommon(true, "delete", addToast);
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const getCandidateSpecialities = (candidate) => {
    let preferedClient = [];
    _.forEach(candidate?.preferredClients, function (client) {
      preferedClient.push({
        id: client.id,
        name: client.name,
      });
    });

    let specialities = [];
    _.forEach(candidate?.candidateSpecialities, function (speciality) {
      specialities.push({
        id: speciality.id,
        name: speciality.name,
      });
    });

    // Set like wards, clients, and specialities
    setLikeWards(candidate?.likeWards);
    setLikeClients(preferedClient);
    setCandidateSpecialities(specialities);

    // Assuming `userProfile?.clients` is already available
    // Otherwise, pass the necessary client here
    if (candidate?.preferredClients?.length > 0) {
      getWardsInSelectedClient(
        candidate.preferredClients[0],
        candidate?.likeWards
      ); // Run the function with the first preferred client
    }
  };

  const getShifts = () => {
    axios.get(MexxarShift).then((res3) => {
      setShifts(res3.data.body);
    });
  };

  const getWardsInSelectedClient = async (client, likeWards) => {
    try {
      const { data } = await axios.get(
        `${MexxarApi}client-wards/clients/${client?.id}`
      );
      const wards = data.body;

      // Filter matching preferred wards and map to desired format
      const wardsList = wards
        .filter((ward) =>
          likeWards.some((item) => ward?.wardId === item?.wardId)
        )
        .map((ward) => ({
          id: ward.wardId, // Ward's original ID for booking purposes
          name: ward.wardName,
          label: ward.wardName,
        }));

      const ward = _.find(wards, { wardName: userProfile?.ward });
      const wardIsInCandidateLikeList = _.find(wardsList, {
        name: userProfile?.ward,
      });

      setWardCheck({
        wardId: isEmpty(ward) ? userProfile?.ward : ward?.wardId,
        doesNotExistAsLikeWard: isEmpty(wardIsInCandidateLikeList),
        wardDoesNotExist: isEmpty(ward),
      });

      // Set the first ward as the selected ward and the matching wards list
      setSelectedWard(wardsList[0]);
      setMatchingLikeWardsWithClient(wardsList);
    } catch (error) {
      console.error("Error fetching wards:", error);
    }
  };
  console.log("selectedWard", selectedWard);

  function checkOutlookLoginStatus() {
    if (accounts.length > 0) {
      // User is logged in
      setOutlookLoggedIn(true);
    } else {
      // User is not logged in
      setOutlookLoggedIn(false);
    }
  }

  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // Successful login

        if (response && response?.account) {
          setOutlookLoggedIn(true);
        } else {
          setOutlookLoggedIn(false);
          setOutlookLoggedInError(true);
        }
      })
      .catch((error) => {
        // Handle login error
        setOutlookLoggedIn(false);
        setOutlookLoggedInError(true);
      });
  };

  const handleInputChange = (e) => {
    setQuery(e);
  };
  const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].id,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        candidate: rows[i],
      });
    }

    if (query != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  function checkIdExists(selectedId, array) {
    return array.some((item) => item.id === selectedId);
  }
  function calculateTotalHours(startTime, endTime) {
    // Parse the time strings into Date objects
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);

    // Calculate the difference in minutes
    let diffInMinutes = (end - start) / 1000 / 60; // Convert milliseconds to minutes

    // Handle the case where the end time is on the next day
    if (diffInMinutes < 0) {
      diffInMinutes += 24 * 60; // Add 24 hours in minutes
    }

    // Calculate hours and minutes
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    // Format hours and minutes to always have two digits (e.g., "17:02")
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    // Return the formatted time as "HH:mm"
    return `${formattedHours}:${formattedMinutes}`;
  }

  useEffect(() => {
    getCandidates();
    return () => {};
  }, [rows]);

  useEffect(() => {
    getShifts();
    return () => {};
  }, []);

  useDidMountEffect(() => {
    getCandidateSpecialities(selectedCandidates.candidate);

    return () => {};
  }, [selectedCandidates]);

  useEffect(() => {
    getDateField();
    return () => {};
  }, [dateSelected]);

  useEffect(() => {
    // Call the function to check the login status
    checkOutlookLoginStatus();
    return () => {};
  }, []);

  useEffect(() => {
    checkTokenExpiration();
  }, [accounts]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label sm={3}>Ref No</Label>

          <Col sm={8} className="mb-2">
            <Input
              type="text"
              placeholder="Ref No"
              name="Ref_No"
              id="Ref_No"
              value={userProfile?.refNo}
              invalid={errors.Ref_No}
              innerRef={register({
                required: "This field is required!",
                maxLength: {
                  value: 150,
                  message: "This field only allows up to 150 characters",
                },
              })}
            />
            {errors.Ref_No && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.Ref_No?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Date</Label>
          <Col sm={8}>
            <Input
              defaultValue={
                userProfile?.date !== undefined && userProfile?.date !== null
                  ? moment(userProfile?.date).format("DD/MM/YYYY") // Display format
                  : ""
              }
              type="text"
              invalid={errors.date || dataErrorStatus}
              placeholder="Date"
              name="Booking_Date_Display"
              readOnly
            />
            {/* Hidden input to submit the original value */}
            <Input
              defaultValue={userProfile?.date || ""}
              type="hidden"
              name="Booking_Date"
              innerRef={register}
            />
            {dataErrorStatus && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">Invalid Date</li>
              </ul>
            )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Shift Type</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="shiftId"
              name="shiftId"
              defaultValue={currentShift}
              value={currentShift}
              invalid={!!errors.shiftId}
              innerRef={register({
                required: "Shift Type is required",
                validate: (value) =>
                  value !== "0" || "Please select a shift type",
              })}
              onChange={(e) => setCurrentShift(Number(e.target.value))}
            >
              <option value="0">Select</option>
              {shifts?.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Input>

            {errors.shiftId && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.shiftId?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Start Time</Label>
          <Col sm={8}>
            <Input
              list="time-options"
              type="text"
              name="Start_Time"
              placeholder="Enter time (HH:MM)"
              value={userProfile?.startTime}
            />
            <datalist id="time-options">
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </datalist>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>End Time</Label>
          <Col sm={8}>
            <Input
              list="time-options"
              type="text"
              name="End_Time"
              placeholder="Enter time (HH:MM)"
              value={userProfile?.endTime}
            />
            <datalist id="time-options">
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </datalist>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={3}>
            <Label>Tag Candidate</Label>
          </Col>
          <Col sm={8}>
            <Select
              name="candidateId"
              className="basic-multi-select"
              placeholder="Search candidates ..."
              classNamePrefix="select"
              onChange={(data) => setSelectedCandidates(data)}
              isLoading={loading}
              options={candidates}
              onInputChange={handleInputChange}
              noOptionsMessage={() => "No results found"}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>{ClientLable}</Label>
          <Col sm={8}>
            <Input
              id="clientId"
              name="clientId"
              value={userProfile?.clients?.name || ""}
              onChange={(e) => setSelectedClient(e.target.value)}
              invalid={!!errors.clientId}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Department</Label>
          <Col sm={8}>
            <Input
              id="departmentId"
              name="departmentId"
              value={userProfile?.ward}
              isInvalid={!!errors.departmentId}
              onChange={(e) => setSelectedWard(e.target.value)}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Candidate Specialities</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="specialityId"
              name="specialityId"
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              {candidateSpecialities &&
                candidateSpecialities.map((item, id) => {
                  return (
                    <option value={item.id} key={id}>
                      {item.name}
                    </option>
                  );
                })}
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Grade</Label>
          <Col sm={8}>
            <Input
              type="text"
              name="grade"
              defaultValue={currentGrade && currentGrade}
              innerRef={register}
              onChange={(selected) => setCurrentGrade(selected.target.value)}
            ></Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Send Confirmation</Label>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="sendConfirmation"
                  className="pointer"
                  value={sendConfirmation}
                  checked={sendConfirmation}
                  onClick={() => setSendConfirmation(!sendConfirmation)}
                />
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>

        {showSpecialRates ? (
          <FormGroup row>
            <Label sm={3}>Special Rate (£)</Label>

            <Col sm={4}>
              <Input
                type="text"
                placeholder="Up lift"
                name="upLift"
                invalid={errors.upLift}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
              {errors.upLift && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.upLift?.message}
                  </li>
                </ul>
              )}
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                placeholder="Bonus"
                name="bonus"
                invalid={errors.bonus}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
              {errors.bonus && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.bonus?.message}
                  </li>
                </ul>
              )}
            </Col>
          </FormGroup>
        ) : null}
        <FormGroup row>
          <Label sm={3}>Break Minutes</Label>
          <Col sm={8}>
            <Input
              type="text"
              placeholder="Break Minutes"
              name="breakTime"
              value={breakMinutes}
              innerRef={register}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                setBreakMinutes(Number(value)); // Update breakMinutes value
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Total Hours</Label>
          <Col sm={8}>
            <Input
              type="text"
              placeholder="Total Hours"
              name="totalHours"
              innerRef={register}
              value={
                startTime && endTime
                  ? calculateTotalHours(startTime, endTime, breakMinutes)
                  : ""
              }
              readOnly // Make it read-only
            />
          </Col>
        </FormGroup>

        <hr />
        {!outlookLoggedIn && sendConfirmation && (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="danger"
          >
            <p>
              To send a booking confirmation via email, kindly ensure that you
              are logged in first
            </p>
            <img src={outlookLogo} style={{ height: 100 }}></img>
            <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
              Login
            </u>
          </Alert>
        )}
        <Alert
          className="d-flex align-items-center justify-content-center flex-column "
          color="info"
        >
          Please make sure to confirm the pay rates before making the booking.
        </Alert>

        <div className="d-flex justify-content-end">
          <Button
            color={ratesConfirmed ? "info" : "warning"}
            className="mr-2"
            onClick={() => setShowHospitalRateModal(!showHospitalRateModal)}
          >
            {ratesConfirmed ? "Show Rates" : "Add Pay Rates"}
          </Button>

          {!sendConfirmation && ratesConfirmed && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={disableCreateButton}
            >
              Create
            </Button>
          )}

          {sendConfirmation && ratesConfirmed && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={disableCreateButton || !outlookLoggedIn}
            >
              Create
            </Button>
          )}
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>

      <ModalComponent
        className="modal-dialog modal-lg"
        show={showHospitalRateModal}
        header="Candidate Rate"
        closeModal={() => setShowHospitalRateModal(false)}
      >
        <ClientRateBookingModal
          incommingPage="addBooking"
          clientOptions={candidateSpecialities}
          grade={currentGrade}
          candidate={selectedCandidates}
          closeModal={() => setShowHospitalRateModal(false)}
          clientId={selectedClient.id}
          // specialityId={selectedSpcaility}
          departmentId={selectedWard ? selectedWard?.id : -1}
          confirmRates={(data) => {
            // setRates(data);
            setRatesConfirmed(true);
            setShowHospitalRateModal(false);
          }}
          ratesConfirmed={ratesConfirmed}
        />
        {/*<ClientRateModal toggle={()=> setShowHospitalRateModal(!showHospitalRateModal)} client={{id : 1}}></ClientRateModal>*/}
      </ModalComponent>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.auth.item,
  };
}

export default connect(mapStateToProps, {})(BookACandidate);
