import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Collapse,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { HOSTMexxar, ClientLable } from "../../../configs/api-config";

import moment from "moment";
import { connect } from "react-redux";
import NoConnection from "../../errorPages/NoConnection";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { FunnelIcon } from "../../../assets/icons/svg";
import ExternalShiftsCardWidget from "../../../widgets/working-card-widget/externalShiftsCardWidget";
import DateInput from "../../../util/common/DateInput";
import dateFormatter from "../../../util/common/dateFormatter";
import useReschdulesFetch from "../../../customHooks/useReschdulesFetch";
import { useSpring, animated } from "react-spring";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";

const ExternalShifts = (props) => {
  const { employeeID } = props;

  const currentDate = new Date();

  const yesterDay = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).subtract(1, "days")
  );
  const tommorow = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).add(10, "days")
  );

  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [initialRows, setInitialRows] = useState([]);
  const [query, setQuery] = useState("");
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [getStartDate, setGetStartDate] = useState(yesterDay);
  const [clientId, setClientId] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [checkDate, setCheckDate] = useState(false);
  const [resetState, setResetState] = useState(false);
  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);
  const [reset, setReset] = useState(false);
  const observer = useRef();

  let url =
    HOSTMexxar +
    "otherShift/filter?dateFrom=" +
    moment(getStartDate).format("YYYY/MM/DD") +
    "&dateTo=" +
    moment(getEndDate).format("YYYY/MM/DD") +
    "&employeeId=" +
    employeeID +
    (clientId ? "&clientID=" + clientId : "");
  // (startTime
  //   ? "&startTime=" + moment(startTime, "HH:mm").format("HH:mm:ss")
  //   : "") +
  // (endTime ? "&endTime=" + moment(endTime, "HH:mm").format("HH:mm:ss") : "");

  const { loading, error, rows, hasMore } = useReschdulesFetch(
    url,
    query,
    pageNumber,
    searchAdvanced,
    reset,
    employeeID
  );

  useDidMountEffect(() => {
    setPageNumber(1);
  }, [reset, searchAdvanced, employeeID]);

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };
  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  console.log("employeeID", employeeID);
  const resetFilters = () => {
    setReset(!reset);
    setGetStartDate(yesterDay);
    setGetEndDate(tommorow);
    setResetState(!resetState);

    setStartTime("");
    setEndTime("");
    // getFieldData();
  };

  const checkDateBefore = () => {
    setCheckDate(
      moment(dateFormatter.formatDateToMMDDYYYY(getStartDate)).isBefore(
        dateFormatter.formatDateToMMDDYYYY(getEndDate)
      )
    );
  };
  useEffect(() => {
    checkDateBefore();
  }, [getStartDate, getEndDate]);

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          id="searchBar"
          onChange={handleSearchChange}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Bookings..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search by Candidate name/Candidate id/Reference No./{ClientLable}
          /Department
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>
                <DateInput
                  id="startDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getStartDate)}
                  onChange={(date) =>
                    setGetStartDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>
                <DateInput
                  id="endDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getEndDate)}
                  onChange={(date) =>
                    setGetEndDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>

              {/* <Col>
                <Label>Start Time</Label>
                <Input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </Col>
              <Col>
                <Label>End Time</Label>
                <Input
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </Col> */}

              <Col></Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={resetFilters}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <ExternalShiftsCardWidget
                key={i}
                userProfile={row}
                reset={() => setResetState(!resetState)}
              />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <ExternalShiftsCardWidget
                key={i}
                userProfile={row}
                reset={() => setResetState(!resetState)}
              />
            </animated.div>
          );
        }
      })}

      {rows?.length == 0 && !loading && <NoSearchResults />}
      {/* {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {filteredRows.length > 0 ? (
        filteredRows
          .sort(sortFunctionAscending)
          .map((row, i) => (
            <ExternalShiftsCardWidget
              key={i}
              userProfile={row}
              reset={() => setResetState(!resetState)}
            />
          ))
      ) : (
        <NoSearchResults />
      )} */}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ExternalShifts);
